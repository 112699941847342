import React from "react";

const Vimeo = ({ videoSrcURL, title, ...props }) => (
  <div>
    <iframe
      className="video"
      src={`${videoSrcURL}?playsinline=0`}
      title={title}
      //   src="https://player.vimeo.com/video/657406236?h=f1277aa4fd"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    />
  </div>
);
export default Vimeo;
