import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { FiList, FiUser, FiVideo } from "react-icons/fi";
import { GiDirectorChair, GiLightBulb } from "react-icons/gi";
import SEO from "../components/seo";
import Image from "../elements/image";
import Layout from "../components/layout";
import Vimeo from "../components/vimeo";

const ProjectDetails = ({ data }) => {
  const projectData = data.projectJson;
  const projectImage = data.projectJson.features;

  const moreVideos = data.projectJson.moreVideos;

  const features = data.projectJson.showFeatures ? (
    <React.Fragment>
      <div className="thumbnail mt--90 mt_md--40 mt_sm--40">
        <Image fluid={projectData.featured_image.childImageSharp.fluid} />
      </div>
      <div className="image-group">
        {projectImage.map((data, index) => (
          <div className="single-image mt--30" key={index}>
            <Img fluid={data.image.childImageSharp.fluid} />
          </div>
        ))}
      </div>
    </React.Fragment>
  ) : null;

  const moreVideosComponent = data.projectJson.showmoreVideos ? (
    <React.Fragment>
      <div className="image-group">
        {moreVideos.map((data, index) => (
          <div className="single-image mt--30" key={index}>
            <Vimeo videoSrcURL={data.videoSrcURL} />
          </div>
        ))}
      </div>
    </React.Fragment>
  ) : null;

  return (
    <Layout>
      <SEO
        title={projectData.title}
        description={`Directing work for ${projectData.client}`}
        image={projectData.featured_image.childImageSharp.fluid}
      />
      <div className="rn-project-details-area rn-section-gap bg-color-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <div className="portfolio-content">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="content-left">
                        <div className="page-top">
                          <h1 className="title_holder">{projectData.title}</h1>
                        </div>
                        <div className="single-image mt--30">
                          <Vimeo videoSrcURL={projectData.videoSrcURL} />
                        </div>

                        <h3 className="mt--20">Details</h3>
                        <ul className="list_holder">
                          {projectData.client && (
                            <li>
                              <span className="icon">
                                <FiUser />
                                Client:
                              </span>
                              <span className="projectinfo">{projectData.client}</span>
                            </li>
                          )}
                          {projectData.agency && (
                            <li>
                              <span className="icon">
                                <GiLightBulb />
                                Agency
                              </span>
                              <span className="projectinfo">{projectData.agency}</span>
                            </li>
                          )}
                          <li>
                            <span className="icon">
                              <FiList />
                              Production
                            </span>
                            <span className="projectinfo">{projectData.production}</span>
                          </li>
                          <li>
                            <span className="icon">
                              <GiDirectorChair />
                              Director
                            </span>
                            <span className="projectinfo">Matthias Neumayer</span>
                          </li>
                          <li>
                            <span className="icon">
                              <FiVideo />
                              DoP:
                            </span>
                            <span className="projectinfo">{projectData.directorofphotography}</span>
                          </li>
                        </ul>
                        <p className="mt--20">{projectData.body}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {moreVideosComponent}

                {features}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    projectJson(id: { eq: $id }) {
      id
      title
      body
      category
      client
      agency
      showFeatures
      production
      directorofphotography
      videoSrcURL
      showFeatures
      showmoreVideos
      moreVideos {
        id
        videoSrcURL
      }
      featured_image {
        childImageSharp {
          fluid(maxHeight: 1000, maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationHeight
            presentationWidth
          }
        }
      }
      features {
        image {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`;
export default ProjectDetails;
